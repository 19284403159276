import fetch from 'cross-fetch'

function Response (status, data) {
  return { status, data }
}

async function get(path, jsonOnUnauth = true) {
  const res = await fetch(
    path,
    {
      method: 'GET',
    }
  )
  const content = (res.status === 200 || jsonOnUnauth) ? await res.json() : null
  const data = content.data

  return Response(res.status, data)
}

/**
 * This function checks if the item (course or lesson) is published.
 * We assume status is a property of the publicContent object.
 * However, not all courses will use this property, so we assume the item is published
 * if no status property can be found.
 * @param obj
 * @returns {boolean}
 */
function isPublished(obj) {
  if (obj?.publicContent?.status) {
    return obj.publicContent.status === "published"
  }
  return true
}

export default function createApi(baseUrl, tokens, devMode, authSessionStorage = false) {
  return {
    async loadUser() {
      const response = await get(`${baseUrl}/courses/`, false);
      const user = {
        "courses": response.data
      }
      return Response(response.status, user)
    },
    async loadCourses () {
      const res = await get(`${baseUrl}/courses/`, true)
      return {
        status: res.status,
        data: res.data.filter(course => devMode ? true : isPublished(course))
      }
    },
    async loadCourse (courseId) {
      const res = await get(`${baseUrl}/courses/${courseId}/`, true)
      if (devMode ? true : isPublished(res.data)) {
        const data = { ...res.data }
        data.lessons = data.lessons.filter(lesson => devMode ? true : isPublished(lesson))
        return {
          status: res.status,
          data
        }
      } else {
        return {
          status: 404,
          data: null
        }
      }
    },
    async loadLesson (courseId, lessonId) {
      const res = await get(`${baseUrl}/courses/${courseId}/lessons/${lessonId}/`, true)
      if (devMode ? true : isPublished(res.data)) {
        return res
      } else {
        return {
          status: 404,
          data: null
        }
      }
    },
    async setComplete(courseId, lessonId, isComplete, user) {
      try {    
        const course = user.courses.find(course => course.id === parseInt(courseId, 10));
        const lesson = course.lessons.find(lesson => lesson.id === parseInt(lessonId, 10));
        lesson.complete = isComplete;
        return true
        // const response = await fetch(
        //   `${baseUrl}/courses/${courseId}/lessons/${lessonId}`,
        //   {
        //     method: 'POST',
        //     headers: {
        //       Accept: 'application/json',
        //       Authorization: `Bearer ${accessToken}`,
        //       'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({ complete: isComplete })
        //   }
        // );
        // if (response.status === 200) {
        //   const course = user.courses.find(course => course.id === courseId);
        //   const lesson = course.lessons.find(lesson => lesson.id === lessonId);
        //   lesson.complete = isComplete;
        //   return true
        // } else {
        //   return false
        // }
      } catch (err) {
        console.log(err);
        return { status: 500 }
      }
    }
  }
}
