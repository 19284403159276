import './Footer.css'

function Footer() {
  return (
    <footer className='Footer'>
      <div>
        <ul>
          <li>
            <a href='https://www.madalinnitu.com/'>Created by Madalin NITU</a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
