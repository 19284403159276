import createCache from './createCache'
import createApi from './createApi'

const baseUrl = process.env.REACT_APP_API_URL === undefined ? "https://api.madalinnitu.com/api" : process.env.REACT_APP_API_URL

function createErrorObject(err, status = 500) {
  console.log(err)
  return { status }
}

export class CourseKitClient {
  constructor(opts) {
    const devMode = opts.devMode || false
    // const tokens = getTokens(opts.authSessionStorage)
    this.api = createApi(opts.baseUrl || baseUrl, {accessToken: null, refreshToken: null}, devMode, opts.authSessionStorage)
    this.cache = createCache(opts.baseUrl || baseUrl, devMode, this.api)
  }

  async loadUser() {
    let obj = this.cache.getUser()
    if (!obj) {
      try {
        const response = await this.api.loadUser()
        obj = this.cache.addUser(response)
      } catch (err) {
        obj = createErrorObject(err)
      }
    }
    return obj
  }

  async loadCourseSummaries() {
    let obj = this.cache.getCourses()
    if (!obj) {
      try {
        const response = await this.api.loadCourses()
        obj = this.cache.addCourses(response)
      } catch (err) {
        obj = createErrorObject(err)
      }
    }
    return obj
  }

  async loadCourse(courseId) {
    let obj = this.cache.getCourse(courseId)
    if (!obj) {
      try {
        const response = await this.api.loadCourse(courseId)
        obj = this.cache.addCourse(courseId, response)
      } catch (err) {
        obj = createErrorObject(err)
      }
    }
    return obj
  }

  async loadLesson(courseId, lessonId) {
    let obj = this.cache.getLesson(courseId, lessonId)
    if (!obj) {
      try {
        const response = await this.api.loadLesson(courseId, lessonId)
        obj = this.cache.addLesson(courseId, lessonId, response)
      } catch (err) {
        obj = createErrorObject(err)
      }
    }
    return obj
  }
}
