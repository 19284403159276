import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAsync } from 'react-async'
import { fetchCourseAndUser } from '../ck'
import Loading from '../components/Loading'
// import CourseHeader from '../components/CourseHeader'
import Error from '../components/Error'

function Encroll() {
  const [email, setEmail] = useState('')
  const [response, setResponse] = useState('Your enrollment was been registered. Thank you!')
  let { courseId } = useParams()
  const { data, error, isPending } = useAsync({
    promiseFn: fetchCourseAndUser,
    courseId,
  })

  function closeModal() {
    document.getElementById('enroll-modal').style.display = 'none'
  }

  if (data) {
    const { course, user } = data
    const { title } = course
    return (
      <div className="Course page">
        <header>
          <p>
            <Link to={'/'}>Back to courses</Link>
          </p>
          <h1>{title}</h1>
        </header>
        <div>
          <input
            type="text"
            id="email"
            className="form-field"
            value={email}
            placeholder="Enter your email" 
            onChange={(e) => setEmail(e.target.value)}
          />

          <button
            className="button primary enroll-button"
            onClick={async () => {
              const res = await user.enrollRedirect(course.id, email)
              if (res.status === 201){
                document.getElementById('enroll-modal').style.display = 'flex'
                setTimeout(closeModal, 3000)
              }else{
                setResponse("You got an error in your enrollment. Please send an email to mnitu.constantin@gmail.com")
                document.getElementById('enroll-modal').style.display = 'flex'
              }
            }}
          >
            Enroll now
          </button>
        </div>
        <div id="enroll-modal" className="modal">
          <div className="modal-content">
            <p>{response}</p>
            <button className="modal-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      </div>
    )
  }
  if (error) {
    return <Error />
  }
  if (isPending) {
    return <Loading />
  }
}

export default Encroll
