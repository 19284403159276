// import { useAsync } from 'react-async'
// import { fetchUser } from '../ck'
import './Nav.css'

function Nav() {
  function NavButton() {
    // const { data } = useAsync(fetchUser)
    return <div />
  }
  return (
    <nav className="Nav">
      <NavButton />
    </nav>
  )
}

export default Nav
