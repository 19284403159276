import { isCourseEnrolled } from './helpers'

export default function ({ status, data }, user) {
  return {
    status,
    courses: data.map((course) => {
      return {
        id: course.id,
        title: course.title,
        // enrolled: user ? isCourseEnrolled(course.id, user) : null,
        meta: {
          description: course.description,
          markdown: course.markdown,
          content: course.content,
          currency: course.currency,
          price: course.price,
          start_date: course.start_date
        }
      }
    })
  }
}
