import fetch from "cross-fetch";

export default function ({ status, data }, baseUrl, devMode) {
  return {
    status,
    user: {
      isAuthenticated() {
        return status === 200
      },
      getName() {
        return data
          ? data.name
          : null
      },
      enrollRedirect(courseId, email = null) {
        if (!courseId) {
          return console.error('Course ID must be supplied.')
        }
        // const searchParams = new URLSearchParams({ courseId,
        //   name,
        //   email,
        //   schoolId,
        //   devMode
        // })

        const res = fetch(
          `${baseUrl}/students/enroll`,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              courseId: courseId,
              email: email,
            })
          }
        )
        return res;
        // if (email) {
        //   window.location.href =`${baseUrl}/passwordlessEnroll?${searchParams.toString()}`
        // } else {
        //   window.location.href =`${baseUrl}/enroll?${searchParams.toString()}`
        // }
      },
    }
  }
}
